// src/pages/Career.js
import React from "react";
import { VerticalTimeline, VerticalTimelineElement } from "react-vertical-timeline-component";
import 'react-vertical-timeline-component/style.min.css';
import { FaUniversity, FaBriefcase, FaRocket } from "react-icons/fa";
import "../styles/Career.css"; // Ensure this path is correct

const Career = ({ onBack }) => {
  return (
    <div className="career-container">
      <button className="back-button" onClick={onBack}>
        Back
      </button>
      <h1 className="info-title">Career Timeline</h1>
      <VerticalTimeline layout="1-column" animate={false}>
        {/* Changed to Media Technology */}
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date="2022 - Present"
          iconStyle={{ background: "#17a2b8", color: "#fff" }}
          icon={<FaBriefcase />}
          contentStyle={{
            background: "#f0f0f0",
            color: "#17a2b8",
            boxShadow: "none",
            padding: "8px 12px",
            borderLeft: "4px solid #17a2b8",
          }}
          contentArrowStyle={{ borderRight: "6px solid #f0f0f0" }}
        >
          <h3 className="vertical-timeline-element-title">KTH - Media Technology</h3>
          <p>Changed the program to Media Technology and aiming for masters in Computer Science.</p>
        </VerticalTimelineElement>

        {/* Own Company Entrepreneur */}
        <VerticalTimelineElement
          className="vertical-timeline-element--entrepreneurship"
          date="2022 - Present"
          iconStyle={{ background: "#ffc107", color: "#212529" }}
          icon={<FaRocket />}
          contentStyle={{
            background: "#f0f0f0",
            color: "#ffc107",
            boxShadow: "none",
            padding: "8px 12px",
            borderLeft: "4px solid #ffc107",
          }}
          contentArrowStyle={{ borderRight: "6px solid #f0f0f0" }}
        >
          <h3 className="vertical-timeline-element-title">Own Company Entrepreneur</h3>
          <p>Started working on personal projects and own company.</p>
        </VerticalTimelineElement>

        {/* KTH - Mechanical Engineering */}
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date="2022 - 2023"
          iconStyle={{ background: "#28a745", color: "#fff" }}
          icon={<FaBriefcase />}
          contentStyle={{
            background: "#f0f0f0",
            color: "#28a745",
            boxShadow: "none",
            padding: "8px 12px",
            borderLeft: "4px solid #28a745",
          }}
          contentArrowStyle={{ borderRight: "6px solid #f0f0f0" }}
        >
          <h3 className="vertical-timeline-element-title">KTH - Mechanical Engineering</h3>
          <p>Studied Mechanical Engineering for 1 year.</p>
        </VerticalTimelineElement>

        {/* NTI Gymnasiet */}
        <VerticalTimelineElement
          className="vertical-timeline-element--education"
          date="2019 - 2022"
          iconStyle={{ background: "#0056b3", color: "#fff" }}
          icon={<FaUniversity />}
          contentStyle={{
            background: "#f0f0f0",
            color: "#0056b3",
            boxShadow: "none",
            padding: "8px 12px",
            borderLeft: "4px solid #0056b3",
          }}
          contentArrowStyle={{ borderRight: "6px solid #f0f0f0" }}
        >
          <h3 className="vertical-timeline-element-title">NTI Gymnasiet - Teknikprogrammet</h3>
          <p>Completed high school focusing on technology.</p>
        </VerticalTimelineElement>
      </VerticalTimeline>
    </div>
  );
};

export default Career;
