// src/components/WelcomeMessage.js
import React, { useEffect, useState } from 'react';
import '../styles/WelcomeMessage.css'; // Ensure this path is correct

const WelcomeMessage = () => {
  const [showCursor, setShowCursor] = useState(true); // Show cursor initially
  const [fadeOut, setFadeOut] = useState(false); // Control fade-out state

  useEffect(() => {
    // Hide the cursor after typing animation completes (2s)
    const timer = setTimeout(() => {
      setFadeOut(true); // Start fade-out animation
      // Hide the cursor after fade-out completes (0.5s)
      const hideTimer = setTimeout(() => {
        setShowCursor(false);
      }, 500);
      return () => clearTimeout(hideTimer);
    }, 2000); // Match with typing animation duration

    return () => clearTimeout(timer);
  }, []);

  return (
    <div id="welcome-message-container">
      <div id="welcome-message">
        Welcome to{' '}
        <span className="highlight">
          korb<strong>net</strong>
        </span>{'! '}
        Ideas, skills, and projects.
        {showCursor && (
          <span className={`cursor ${fadeOut ? 'fade-out' : ''}`}>|</span>
        )}
      </div>
    </div>
  );
};

export default WelcomeMessage;
