import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import '../../styles/PageStyles.css'; // Ensure the path is correct

const ProjectDetail = ({ project, onBack }) => {
  const [modalImage, setModalImage] = useState(null);

  useEffect(() => {
    if (!project) return;

    const projectDetailsEl = document.querySelector('.project-details');
    if (!projectDetailsEl) return;

    const images = projectDetailsEl.querySelectorAll('img');
    images.forEach((img) => {
      img.style.cursor = 'pointer';
      img.addEventListener('click', () => {
        setModalImage(img.src);
      });
    });

    return () => {
      images.forEach((img) => {
        img.removeEventListener('click', () => {
          setModalImage(img.src);
        });
      });
    };
  }, [project]);

  const handleCloseModal = () => setModalImage(null);

  if (!project) return null;

  // Create the modal element
  const modalElement = modalImage ? (
    <div className="image-modal" onClick={handleCloseModal}>
      <img src={modalImage} alt="Full Screen" />
    </div>
  ) : null;

  return (
    <>
      <div className="page-container">
        <button className="back-button" onClick={onBack}>
          Back
        </button>

        {/* Render the details as HTML */}
        <div
          className="project-details"
          dangerouslySetInnerHTML={{ __html: project.details }}
        />
      </div>

      {/* Portal the modal to the body */}
      {ReactDOM.createPortal(modalElement, document.body)}
    </>
  );
};

export default ProjectDetail;
