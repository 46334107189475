import React, { useEffect, useMemo, useState } from 'react';
import '../styles/Bubble.css';

const Bubble = ({ id, onPop }) => {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 620); // Mobile screen size check

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 620);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const size = useMemo(() => (isSmallScreen ? Math.random() * 60 + 20 : Math.random() * 90 + 30), [isSmallScreen]);
  const left = useMemo(() => (isSmallScreen ? Math.random() * 20 : Math.random() * 30), [isSmallScreen]);
  const animationDuration = useMemo(() => Math.random() * 5 + 8, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      onPop(id);
    }, animationDuration * 1000);

    return () => clearTimeout(timer);
  }, [animationDuration, id, onPop]);

  const handleClick = (e) => {
    const { clientX, clientY } = e; // Get click position
    onPop(id, { x: clientX, y: clientY }); // Pass click position to parent
  };

  return (
    <div
      className="bubble-rise"
      style={{
        left: `${left}vw`,
        width: `${size}px`,
        height: `${size}px`,
        animationDuration: `${animationDuration}s`,
        zIndex: 1500,
      }}
      onClick={handleClick}
    />
  );
};

export default Bubble;
