// src/pages/About.js
import React from 'react';
import '../styles/PageStyles.css';

const About = ({ onBack }) => {
  return (
    <div className="page-container">
      {/* Back Button */}
      <button className="back-button" onClick={onBack}>
        Back
      </button>

      {/* Title */}
      <h1 className="info-title">About Me and Korbnet</h1>

      {/* Content */}
      <div className="content-div">
        <p>
          Hi, my name is A.S., and I’m a 21-year-old engineering student in computer science based in
          Stockholm, Sweden. Since a young age, I’ve been fascinated by technology and the endless
          possibilities it offers. Over the years, I’ve worked on transforming what began as a hobby into
          professional skills that I now use to create meaningful projects.
        </p>

        <p>
          Korbnet is my individual company, where I bring together my personal interests and professional
          aspirations. This website serves as a platform to showcase the projects I’ve worked on, both
          personal and professional. It’s also a space for sharing ideas, connecting with potential
          collaborators, and exploring opportunities for the future.
        </p>

        <p>
          At Korbnet, I aim to combine creativity, technical expertise, and a passion for innovation to
          deliver unique solutions. Whether you’re here to learn more about my journey, explore my projects,
          or discuss a potential collaboration, I hope you find something inspiring.
        </p>

        <p>
          Feel free to check out the projects I’ve worked on or reach out to connect—let’s create something
          amazing together.
        </p>
      </div>
    </div>
  );
};

export default About;
