// src/pages/Projects/data.js

export const projectsData = [


  // Thorbygg
  {
    id: 'thorbygg',
    title: 'ThorBygg AB - Construction Company',
    image: '/img/projects/thorbyggab/Pic0.png',
    alt: 'ThorBygg AB - Construction Company',
    details: `
      <div class="project-date">Sep 2022</div>
      <h1 class="info-title">ThorBygg AB - Professional Construction Website</h1>
      <p>
        The website for ThorBygg AB was created to give the construction company a clean and professional online presence. It provides key information about their services and contact details in a user friendly format, making it easy for potential clients and partners to connect.
      </p>
      <p>
        The site features a well organized services page and a responsive design that adapts across devices. A contact form is integrated, allowing users to easily get in touch for consultations or inquiries.
      </p>
      
      <div class="project-images">
        <img src="/img/projects/thorbyggab/Pic1.png" alt="ThorBygg AB - Homepage with Company Overview">
        <p>
          The homepage highlights ThorBygg AB's mission and expertise, offering a strong first impression to visitors.
        </p>
        
        <img src="/img/projects/thorbyggab/Pic2.png" alt="ThorBygg AB - Services Listing">
        <p>
          The services section outlines the company's offerings, catering to both residential and commercial project needs.
        </p>
        
        <img src="/img/projects/thorbyggab/Pic3.png" alt="ThorBygg AB - Mobile View of Contact Form">
        <p>
          The website includes a mobile-friendly design, with a functional contact form that simplifies inquiries from any device.
        </p>
      </div>
      
      <p>
        <strong>Technical Overview:</strong> The website is built using WordPress, a widely used content management system. It utilizes:
      </p>
      <ul class="custom-bullet">
        <li>Elementor Pro: For custom page designs and layouts.</li>
        <li>W3 Total Cache: To improve site speed through caching.</li>
        <li>WP Smush: For optimizing images and enhancing load times.</li>
      </ul>
      <p>
        Additional features include responsive design, SEO optimization for better visibility, and performance enhancements like lazy loading for images.
      </p>
    `
  },

  // UP Hemsidan
  {
    id: 'urbanproperties',
    title: 'Urban Properties AB - Real Estate Platform',
    image: '/img/projects/uphemsidan/Pic0.png',
    alt: 'Urban Properties AB - Real Estate Platform',
    details: `
      <div class="project-date">Feb 2023</div>
      <h1 class="info-title">Urban Properties AB - Real Estate Platform Improvement</h1>
      <p>
        Urban Properties AB's platform underwent a redesign to improve performance, enhance navigation, and implement modern features for a smoother user experience.
      </p>
  
      <p><strong>Key Improvements:</strong></p>
      <ul class="custom-bullet">
        <li>Automated Newsletter API: Integrated the Notified News API to automatically update and display news items within the platform.</li>
        <li>Project Tab Fixes: Redesigned the project display for better performance, clearer visuals, and a more intuitive structure.</li>
        <li>Google Maps Integration: Customized map styling to match the site's aesthetic, providing a solid look and feel for project locations.</li>
        <li>General UI Updates: Enhanced both the project and news sections, resulting in a cleaner and more modern presentation.</li>
      </ul>
  
      <div class="project-images">
        <img src="/img/projects/uphemsidan/Pic1.png" alt="Urban Properties - Listings Page">
        <p>The improved listings page showcases updated property tiles and a more user-friendly design.</p>
  
        <img src="/img/projects/uphemsidan/Pic2.png" alt="Urban Properties - Project Display">
        <p>The redesigned project display section provides clear visuals, better layout, and improved navigation for browsing projects.</p>
  
        <img src="/img/projects/uphemsidan/Pic3.png" alt="Urban Properties - News Tab">
        <p>The automated news section, powered by the Notified News API, keeps users informed with the latest updates integrated directly into the site.</p>
  
        <img src="/img/projects/uphemsidan/Pic4.png" alt="Urban Properties - Google Maps Integration">
        <p>Customized Google Maps integration, featuring a green theme that aligns with the site's design and highlights project locations clearly.</p>
  
        <img src="/img/projects/uphemsidan/Pic5.png" alt="Urban Properties - Mobile View">
        <p>A mobile optimized view ensures that property listings and other features are accessible and easy to navigate on any device.</p>
      </div>
    `
  },

  // Docal
  {
    id: 'docal',
    title: 'Docal - Healthcare Management System',
    image: '/img/projects/docal/Pic0.png',
    alt: 'Docal - Healthcare Management System',
    details: `
    <div class="project-date">Sep 2023</div>
    <h1 class="info-title">Docal - Healthcare Appointment and Management System</h1>
<p>
  <strong>Docal</strong> is an advanced healthcare management system designed to simplify operations for private clinics, doctors, and patients. The platform features three interconnected dashboards customized for clinics, doctors, and patients, ensuring smooth communication and management across all stakeholders.
</p>

    <h2>Clinic Dashboard</h2>
    <p>
      The clinic dashboard serves as the central hub for clinics, enabling them to manage registered doctors, view performance statistics, and oversee payments and fees. Clinics act as administrators, ensuring smooth operations within the healthcare ecosystem.
    </p>
    <h2>Doctor Dashboard</h2>
    <p>
      The doctor’s dashboard provides powerful tools for managing patient visits, active medications, and statistics. Key features include:
    </p>
    <ul class="custom-bullet">
      <li>Overview of today’s schedule, patient statistics, and active prescriptions.</li>
      <li>Search and filter options for efficient patient management.</li>
      <li>Detailed patient records, including visit history, active medications, and upcoming appointments.</li>
      <li>Customizable visit details and diagnosis forms with statistical tracking.</li>
      <li>Integration with the WHO API for accurate disease identification during diagnoses.</li>
      <li>Canvas-based image annotation tools for X-rays and patient photos.</li>
    </ul>
    <div class="project-images">
      <img src="/img/projects/docal/Pic1.png" alt="Docal - Appointment Page">
      <p>Appointment management interface for streamlined scheduling and tracking.</p>
      
      <img src="/img/projects/docal/Pic2.png" alt="Docal - Patient Dashboard">
      <p>Comprehensive dashboard with quick access to patient details and medical history.</p>
    </div>
    <h2>Appointments and Prescriptions</h2>
    <p>
      The platform simplifies appointment scheduling and prescription management. Doctors can set appointment durations and notify patients automatically. The prescription module integrates with a medication API to ensure accurate drug information and generates printable prescriptions for seamless handover to patients or pharmacists.
    </p>
    <div class="project-images">
      <img src="/img/projects/docal/Pic3.png" alt="Docal - Doctor Messaging System">
      <p>Doctor-patient messaging interface for direct communication.</p>
      
      <img src="/img/projects/docal/Pic4.png" alt="Docal - Prescription Interface">
      <p>Intuitive prescription tool for accurate and efficient medication management.</p>
    </div>
    <h2>Additional Features</h2>
    <p>
      While messaging and patient-side features are still under development, the clinic and doctor dashboards are fully functional. Future iterations will include enhanced messaging capabilities, patient profile management, and more.
    </p>
    <div class="project-images">
      <img src="/img/projects/docal/Pic5.png" alt="Docal - Messaging System">
      <p>Messaging system for real-time communication between doctors and patients.</p>
      
      <img src="/img/projects/docal/Pic6.png" alt="Docal - Doctor's Quick Access">
      <p>Quick-access tools for streamlined doctor workflows.</p>
      
      <img src="/img/projects/docal/Pic7.png" alt="Docal - Visit Details Form">
      <p>Customizable forms for detailed visit and diagnosis records.</p>
      
      <img src="/img/projects/docal/Pic8.png" alt="Docal - WHO API Integration for Diagnoses">
      <p>WHO API integration for precise and verified disease identification.</p>
      
      <img src="/img/projects/docal/Pic9.png" alt="Docal - Statistics for Doctors">
      <p>Statistical dashboards to track performance and patient outcomes.</p>
      
      <img src="/img/projects/docal/Pic10.png" alt="Docal - File Upload and Annotation Tools">
      <p>Annotation tools for X-rays and patient-related images.</p>
    </div>
  `
  },

  // General BeachBar
  {
    id: 'generalbeachbar',
    title: 'General Beach Bar - Order Management System',
    image: '/img/projects/generalbeachbar/Pic0.png',
    alt: 'General Beach Bar - Order Management System',
    details: `
    <div class="project-date">Jul 2023</div>
    <h1 class="info-title">General Beach Bar - Order Management System</h1>
    <p>
      The <strong>General Beach Bar Order Management System</strong> is a custom built solution designed to simplify the ordering process for sunbed clients at a beach bar. Customers can conveniently place orders from their sunbeds using a secure QR code system, improving the overall experience while optimizing management and delivery workflows.
    </p>
    <h2>Client-side System</h2>
    <p>
      Upon arrival, customers receive a unique QR code linked to their sunbed. Scanning the code provides access to the bar’s menu, displaying items and prices. Clients can easily select items, add them to their basket, and place orders directly from their phones. Each session is temporary and expires to prevent misuse after the user leaves the beach.
    </p>
    <div class="project-images">
      <img src="/img/projects/generalbeachbar/Pic1.png" alt="Beach Bar Ordering System - Customer UI">
    </div>
    <h2>Beach Bar Staff Interface</h2>
    <p>
      Orders are instantly transmitted to the back end system, where staff are notified via sound alerts. A live dashboard shows a sunbed layout, numbered by rows and columns, helping staff quickly locate clients. A green light flashes on the dashboard to indicate the order's exact location.
    </p>
    <div class="project-images">
      <img src="/img/projects/generalbeachbar/Pic2.png" alt="Beach Bar Ordering System - Admin Dashboard">
    </div>
    <h2>Efficient Order Management</h2>
    <p>
      Staff can view detailed orders, including items, prices, and sunbed locations. Once fulfilled, orders can be marked as complete. The system logs all orders, allowing staff to track, edit, or delete them if necessary. It also tracks popular sunbeds and provides insights into peak times and high demand areas.
    </p>
    <div class="project-images">
      <img src="/img/projects/generalbeachbar/Pic3.png" alt="Beach Bar Ordering System - Sunbed Layout">
    </div>
    <h2>Project Highlights</h2>
    <p>
      This system offers a modern, efficient way to manage beach bar orders. It minimizes wait times, optimizes staff workflow, and provides valuable insights into customer behavior and high-activity areas.
    </p>
    <p>
      The project was completed within a short timeframe and with minimal investment, leaving room for significant improvements in design and data presentation
    </p>
    <div class="project-images">
      <img src="/img/projects/generalbeachbar/Pic4.png" alt="Beach Bar Ordering System - Order Logs">
    </div>
  `
  },



  // ttScent
  {
    id: 'ttscent',
    title: 'ttScent - E-Commerce Platform for Perfume Samples',
    image: '/img/projects/ttscent/Pic0.png',
    alt: 'ttScent - E-Commerce Platform for Perfume Samples',
    details: `
      <div class="project-date">Oct 2023</div>
      <h1 class="info-title">ttScent - E-Commerce Platform for Perfume Samples</h1>
      <p><strong>ttScent</strong> is a premium e-commerce platform offering customers the chance to discover and explore new fragrances through personalized perfume samples. With a focus on exclusivity and a luxurious shopping experience, the platform combines elegant design with seamless functionality to create an unforgettable user journey.</p>
      
      <h2>Exclusive Welcome Page</h2>
      <p>The welcome page features a personalized animation, creating a captivating first impression for users. The sleek, modern design enhances the platform’s luxurious image and sets the tone for a premium shopping experience.</p>
      
      <div class="project-images">
        <img src="img/projects/ttscent/Pic1.png" alt="ttScent - Welcome Animation">
      </div>
      
      <h2>Product Page</h2>
      <p>The product page is central to the user experience, displaying products with size options and stock alerts. If a product is out of stock, customers can express interest, notifying the seller and enabling restocking based on demand. The clean, user-friendly layout ensures smooth navigation and effortless shopping.</p>
      
      <div class="project-images">
        <img src="img/projects/ttscent/Pic2.png" alt="ttScent - Product Display">
        <img src="img/projects/ttscent/Pic3.png" alt="ttScent - Product Details">
      </div>
      
      <h2>Sample Cart and Checkout</h2>
      <p>Adding samples to the cart triggers a smooth animation for instant feedback. The cart page follows a minimalist design, offering clarity throughout the process. During checkout, customers can select shipping options, track orders in real-time, and receive notifications about their order progress via email.</p>
      
      <div class="project-images">
        <img src="img/projects/ttscent/Pic4.png" alt="ttScent - Sample Cart Animation">
        <img src="img/projects/ttscent/Pic5.png" alt="ttScent - Checkout Page">
      </div>
      
      <h2>Order Management and Shipping</h2>
      <p>Sellers benefit from a robust order management system integrated with the <strong>PostNord API</strong>, enabling automatic shipping label generation and real-time tracking. The system simplifies logistics while allowing sellers to update order statuses and communicate directly with customers.</p>
      
      <div class="project-images">
        <img src="img/projects/ttscent/Pic6.png" alt="ttScent - Order Management Dashboard">
        <img src="img/projects/ttscent/Pic7.png" alt="ttScent - PostNord Integration">
      </div>
      
      <h2>Analytics and Insights</h2>
      <p>The analytics dashboard provides sellers with valuable insights into product performance and customer behavior. Metrics such as top-selling samples, live view counts, and interest in out-of-stock items help sellers optimize their offerings and cater to customer preferences effectively.</p>
      
      <div class="project-images">
        <img src="img/projects/ttscent/Pic8.png" alt="ttScent - Analytics Dashboard">
        <img src="img/projects/ttscent/Pic9.png" alt="ttScent - Product Interest Tracking">
        <img src="img/projects/ttscent/Pic10.png" alt="ttScent - Live View Insights">
      </div>
      
      <p>This project blends a premium user experience with efficient backend functionality, making it a standout platform for both customers and sellers in the perfume sampling market.</p>
    `
  }
  
  

];
