// src/pages/Skills.js
import React from 'react';
import '../styles/PageStyles.css'; // Ensure you have appropriate styles

const Skills = ({ onBack }) => {
  return (
    <div className="page-container">
      <button className="back-button" onClick={onBack}>
        Back
      </button>

      <h1 className="info-title">Skills</h1>
      <p>
        As a developer and problem solver, I have gathered a broad range of technical, creative,
        and collaborative skills that help me deliver solutions. Here are some of the key areas
        where I operate for now:
      </p>

      <h2>Programming & Development</h2>
      <ul className="custom-bullet">
        <li>
          <strong>Deep Knowledge:</strong> Python, JavaScript, HTML, CSS
        </li>
        <li>
          <strong>Medium Knowledge:</strong> .NET, C#, C++, Swift (Apple development)
        </li>
        <li>
          <strong>Frameworks & Libraries:</strong> React, Vue, Node.js, Express.js
        </li>
        <li>
          <strong>Databases:</strong> MySQL, MongoDB, Firebase
        </li>
        <li>
          <strong>Game Development & 3D modeling:</strong> Unity, Unreal Engine 5 (basic), Blender (basic)
        </li>

      </ul>

      <h2>API Integration & System Control</h2>
      <ul className="custom-bullet">
        <li>Experienced in API integration for smooth functionality</li>
        <li> Git/GitHub handling</li>
        <li> Deployment using Render, Heroku, and AWS</li>
      </ul>

      <h2>Design & User Experience</h2>
      <ul className="custom-bullet">
        <li>
          Strong skills in UX design to enhance usability and accessibility
        </li>
        <li>
          UI design expertise, creating visually appealing and user-friendly interfaces
        </li>
        <li>
          Prototyping and user testing to refine design concepts and improve user satisfaction
        </li>
      </ul>

      <h2>Problem Solving & Beyond</h2>
      <ul className="custom-bullet">
        <li>
          Good at solving tech related challenges, offering well thought solutions to complex problems
        </li>
        <li>
          Strong collaborative and communication skills, ensuring good flow in teamwork
        </li>
        <li>
          Always curious to learn new tools and technologies to stay ahead in the forever evolving tech industry
        </li>
      </ul>

      <h2>Multimedia & Creativity</h2>
      <ul className="custom-bullet">
        <li>
          Image and video editing using tools like Adobe Photoshop and Premiere Pro
        </li>
        <li>
          Experienced with other multimedia platforms for creative content production
        </li>
      </ul>



    </div>
  );
};

export default Skills;
