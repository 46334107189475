// src/components/TopRightMenu.js
import React, { useState, useEffect, useRef } from "react";
import "../styles/TopRightMenu.css";

const TopRightMenu = ({ onInfoClick }) => {
  const [isDarkMode, setIsDarkMode] = useState(true); // Start in dark mode by default
  const [lightToggleIcon, setLightToggleIcon] = useState("/img/sun.png");
  const [isHovered, setIsHovered] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 480);
  const menuRef = useRef(null);

  useEffect(() => {
    // Default to dark mode
    document.body.classList.add("dark-mode");
    setIsDarkMode(true);
    setLightToggleIcon("/img/sun.png");
  }, []);

  useEffect(() => {
    const handleResize = () => {
      const mobile = window.innerWidth <= 480;
      setIsMobile(mobile);
      if (!mobile) {
        setIsMenuOpen(false); // Ensure menu is closed on desktop
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const toggleDarkMode = () => {
    if (isDarkMode) {
      // Switch to light mode
      document.body.classList.remove("dark-mode");
      setIsDarkMode(false);
      setLightToggleIcon("/img/moon.png"); // Show moon when in light mode
    } else {
      // Switch back to dark mode
      document.body.classList.add("dark-mode");
      setIsDarkMode(true);
      setLightToggleIcon("/img/sun.png"); // Show sun in dark mode
    }
  };

  useEffect(() => {
    if (isMobile && isMenuOpen) {
      const timer = setTimeout(() => {
        setIsMenuOpen(false);
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [isMobile, isMenuOpen]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        isMobile &&
        isMenuOpen &&
        menuRef.current &&
        !menuRef.current.contains(event.target)
      ) {
        setIsMenuOpen(false);
      }
    };

    if (isMobile && isMenuOpen) {
      document.addEventListener("click", handleClickOutside);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isMobile, isMenuOpen]);

  return (
    <div
      className="top-right-menu"
      ref={menuRef}
      onMouseEnter={() => !isMobile && setIsHovered(true)}
      onMouseLeave={() => !isMobile && setIsHovered(false)}
    >
      <div
        className={`main-bubble ${isMenuOpen ? "active" : ""}`}
        onClick={() => {
          if (isMobile) {
            setIsMenuOpen((prev) => !prev);
          }
        }}
      >
        <img src="/img/qmark.png" alt="Question Mark" />
      </div>
      <div className={`sub-bubbles ${isHovered || isMenuOpen ? "show" : ""}`}>
        <div
          className="sub-bubble"
          onClick={() => {
            toggleDarkMode();
            if (isMobile) setIsMenuOpen(false);
          }}
          id="switch-lights-bubble"
        >
          <img id="light-toggle-icon" src={lightToggleIcon} alt="Toggle Mode" />
        </div>
        <div
          className="sub-bubble"
          onClick={() => {
            onInfoClick();
            if (isMobile) setIsMenuOpen(false);
          }}
        >
          <img src="/img/i2.png" alt="Info" />
        </div>
      </div>
    </div>
  );
};

export default TopRightMenu;
