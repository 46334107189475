import React, { useState } from 'react';
import '../styles/PageStyles.css';
import linkedinLogo from '../Materials/linkedin.png';
import instagramLogo from '../Materials/instagram.png';
import emailLogo from '../Materials/email.png';

const Contact = ({ onBack }) => {
  const [formData, setFormData] = useState({
    name: '',
    surname: '',
    email: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({...formData, [name]: value});
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    alert('Thank you for reaching out! I will get back to you as soon as possible.');
    // Add form submission logic here
  };

  return (
    <div className="page-container">
      <button className="back-button" onClick={onBack}>Back</button>
      <div className="contact-content">
        <h1 className="info-title">Contact Me</h1>
        <p>
          For any questions, collaborations, projects, or help, feel free to contact me. 
        </p>
        <p>
        You can use the links
        below or fill in the contact form, and I’ll respond as soon as I’m available!
        </p>

        <div className="contact-links">
          <a href="https://www.linkedin.com/in/aren-seferi-098601238/?originalSubdomain=se" target="_blank" rel="noopener noreferrer">
            <img src={linkedinLogo} alt="LinkedIn" className="social-logo" />
          </a>
          <a href="https://www.instagram.com/aren.exe/" target="_blank" rel="noopener noreferrer">
            <img src={instagramLogo} alt="Instagram" className="social-logo" />
          </a>
          <a href="mailto:info@korbnet.com">
            <img src={emailLogo} alt="Email" className="social-logo" />
          </a>
        </div>

        {/* Wrapper for the form */}
        <div className="contact-form-container">
          <form className="contact-form" onSubmit={handleSubmit}>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              placeholder="Name"
              className="form-input"
              required
            />
            <input
              type="text"
              name="surname"
              value={formData.surname}
              onChange={handleChange}
              placeholder="Surname"
              className="form-input"
              required
            />
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Email"
              className="form-input form-fullwidth"
              required
            />
            <textarea
              name="message"
              value={formData.message}
              onChange={handleChange}
              placeholder="Message"
              className="form-textarea form-fullwidth"
              required
            ></textarea>
            <button type="submit" className="submit-button">Submit</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;
