// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/styles/BubbleContainer.css */
.bubble-container {
    position: fixed;
    width: 100%;
    height: 100%;
    overflow: hidden;
    top: 0;
    left: 0;
    pointer-events: none; /* Allow clicks to pass through except on bubbles */
  }
  
  .bubble-container > * {
    pointer-events: all; /* Enable pointer events on child elements (bubbles) */
  }
  
  @media (max-width: 620px) {
    .bubble-rise {
      width: 40px; /* Default size */
      height: 40px; /* Default size */
    }
  
    .bubble-container {
      width: 80%; /* Constrain bubbles to 80% of the screen width */
      left: 0; /* Keep them more to the left */
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/styles/BubbleContainer.css"],"names":[],"mappings":"AAAA,mCAAmC;AACnC;IACI,eAAe;IACf,WAAW;IACX,YAAY;IACZ,gBAAgB;IAChB,MAAM;IACN,OAAO;IACP,oBAAoB,EAAE,mDAAmD;EAC3E;;EAEA;IACE,mBAAmB,EAAE,sDAAsD;EAC7E;;EAEA;IACE;MACE,WAAW,EAAE,iBAAiB;MAC9B,YAAY,EAAE,iBAAiB;IACjC;;IAEA;MACE,UAAU,EAAE,iDAAiD;MAC7D,OAAO,EAAE,+BAA+B;IAC1C;EACF","sourcesContent":["/* src/styles/BubbleContainer.css */\n.bubble-container {\n    position: fixed;\n    width: 100%;\n    height: 100%;\n    overflow: hidden;\n    top: 0;\n    left: 0;\n    pointer-events: none; /* Allow clicks to pass through except on bubbles */\n  }\n  \n  .bubble-container > * {\n    pointer-events: all; /* Enable pointer events on child elements (bubbles) */\n  }\n  \n  @media (max-width: 620px) {\n    .bubble-rise {\n      width: 40px; /* Default size */\n      height: 40px; /* Default size */\n    }\n  \n    .bubble-container {\n      width: 80%; /* Constrain bubbles to 80% of the screen width */\n      left: 0; /* Keep them more to the left */\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
