// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/styles/ScoreCounter.css */
#score-counter {
    position: fixed;
    top: 20px;
    left: 20px;
    width: 50px;
    height: 50px;
    background-color: rgba(255, 255, 255, 0.9);
    color: #333;
    font-size: 1.2em;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.3);
    z-index: 2000; /* Ensure it stays above bubbles and emojis */
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  #score-counter:hover {
    background-color: #007BFF;
    color: white;
  }
  `, "",{"version":3,"sources":["webpack://./src/styles/ScoreCounter.css"],"names":[],"mappings":"AAAA,gCAAgC;AAChC;IACI,eAAe;IACf,SAAS;IACT,UAAU;IACV,WAAW;IACX,YAAY;IACZ,0CAA0C;IAC1C,WAAW;IACX,gBAAgB;IAChB,iBAAiB;IACjB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,kBAAkB;IAClB,2CAA2C;IAC3C,aAAa,EAAE,6CAA6C;IAC5D,uDAAuD;EACzD;;EAEA;IACE,yBAAyB;IACzB,YAAY;EACd","sourcesContent":["/* src/styles/ScoreCounter.css */\n#score-counter {\n    position: fixed;\n    top: 20px;\n    left: 20px;\n    width: 50px;\n    height: 50px;\n    background-color: rgba(255, 255, 255, 0.9);\n    color: #333;\n    font-size: 1.2em;\n    font-weight: bold;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    border-radius: 50%;\n    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.3);\n    z-index: 2000; /* Ensure it stays above bubbles and emojis */\n    transition: background-color 0.3s ease, color 0.3s ease;\n  }\n  \n  #score-counter:hover {\n    background-color: #007BFF;\n    color: white;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
