// src/components/Orb.js
import React, { forwardRef } from 'react';

const Orb = forwardRef(({ label, onClick, onMouseEnter, onMouseLeave }, ref) => (
  <div
    className="orb-wrapper"
    ref={ref}
    onClick={onClick}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
  >
    <div className="orb">
      <div className="orb-text">{label}</div>
    </div>
  </div>
));

export default Orb;
