// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/index.css */

/* Default Light Mode: Light Gray Background and Dark Text */
body {
  background-color: #c1c1c1;  /* Light gray background */
  color: #333333;             /* Dark text */
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
               'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
               sans-serif;
  transition: background-color 0.3s ease, color 0.3s ease;
}

/* Dark Mode Overrides */
body.dark-mode {
  background-color: #333333; /* Dark background */
  color: #ffffff;             /* White text */
}

/* Ensure elements inherit color */
.email-text {
  color: inherit;
}

.page-container {
  background-color: inherit; /* Inherit from body */
  color: inherit;            /* Inherit from body */
}

#score-counter {
  background-color: rgba(255, 255, 255, 0.9);
  color: #333;
}

body.dark-mode #score-counter {
  background-color: rgba(255, 255, 255, 0.9);
  color: #333;
}

/* Ensure all other text elements inherit color */
* {
  color: inherit;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA,kBAAkB;;AAElB,4DAA4D;AAC5D;EACE,yBAAyB,GAAG,0BAA0B;EACtD,cAAc,cAAc,cAAc;EAC1C,SAAS;EACT;;yBAEuB;EACvB,uDAAuD;AACzD;;AAEA,wBAAwB;AACxB;EACE,yBAAyB,EAAE,oBAAoB;EAC/C,cAAc,cAAc,eAAe;AAC7C;;AAEA,kCAAkC;AAClC;EACE,cAAc;AAChB;;AAEA;EACE,yBAAyB,EAAE,sBAAsB;EACjD,cAAc,aAAa,sBAAsB;AACnD;;AAEA;EACE,0CAA0C;EAC1C,WAAW;AACb;;AAEA;EACE,0CAA0C;EAC1C,WAAW;AACb;;AAEA,iDAAiD;AACjD;EACE,cAAc;AAChB","sourcesContent":["/* src/index.css */\n\n/* Default Light Mode: Light Gray Background and Dark Text */\nbody {\n  background-color: #c1c1c1;  /* Light gray background */\n  color: #333333;             /* Dark text */\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n               'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n               sans-serif;\n  transition: background-color 0.3s ease, color 0.3s ease;\n}\n\n/* Dark Mode Overrides */\nbody.dark-mode {\n  background-color: #333333; /* Dark background */\n  color: #ffffff;             /* White text */\n}\n\n/* Ensure elements inherit color */\n.email-text {\n  color: inherit;\n}\n\n.page-container {\n  background-color: inherit; /* Inherit from body */\n  color: inherit;            /* Inherit from body */\n}\n\n#score-counter {\n  background-color: rgba(255, 255, 255, 0.9);\n  color: #333;\n}\n\nbody.dark-mode #score-counter {\n  background-color: rgba(255, 255, 255, 0.9);\n  color: #333;\n}\n\n/* Ensure all other text elements inherit color */\n* {\n  color: inherit;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
