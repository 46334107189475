// src/components/OrbitalLayout.js
import React, { useEffect, useRef, useState, useMemo, useCallback } from 'react';
import Orb from './Orb';
import '../styles/OrbitalLayout.css';
import logoImage from '../Materials/logo1.png';
import WelcomeMessage from './WelcomeMessage';

// Import page components
import About from '../pages/About';
import Skills from '../pages/Skills';
import Career from '../pages/Career';
import Contact from '../pages/Contact';
import Projects from '../pages/Projects/Projects';

import Info from '../pages/Info'; 
import TopRightMenu from './TopRightMenu'; 

const OrbitalLayout = () => {
  const orbsData = useMemo(() => ['About', 'Skills', 'Career', 'Contact', 'Projects'], []);
  const orbitalLayoutRef = useRef(null);
  const centerLogoRef = useRef(null);
  const orbRefs = useRef([]);

  const hoverEnabledRef = useRef(false); // Controls whether hover is enabled
  const rotationSpeedRef = useRef(5); // Base rotation speed
  const isFastRotatingRef = useRef(false); // Flag to track fast rotation
  const totalFastRotationTime = 300; // Duration for fast rotation in ms

  const [activePage, setActivePage] = useState(null); // State for active page
  const [orbsHidden, setOrbsHidden] = useState(false); // State to hide orbs

  const rotationDegreesRef = useRef(0);
  const rotationPausedRef = useRef(false);
  const selectedOrbIndexRef = useRef(null);


  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const center = useMemo(
    () => ({
      x: windowSize.width / 2,
      y: windowSize.height / 2,
    }),
    [windowSize]
  );

  const waveDuration = 1500;
  const fastRotationDuration = 500;
  const minRotationSpeed = 0.01;
  const decelerationRate = 0.99;

  const isSmallScreen = windowSize.width <= 620;
  const orbitDistance = isSmallScreen ? 150 : 220;

  // State variables for hover states
  const [hoveredOrbIndex, setHoveredOrbIndex] = useState(null);
  const [isLogoHovered, setIsLogoHovered] = useState(false);

  // Refs to keep track of latest hover states
  const hoveredOrbIndexRef = useRef(null);
  const isLogoHoveredRef = useRef(false);

  // Update refs when state changes
  useEffect(() => {
    hoveredOrbIndexRef.current = hoveredOrbIndex;
  }, [hoveredOrbIndex]);

  useEffect(() => {
    isLogoHoveredRef.current = isLogoHovered;
  }, [isLogoHovered]);

  // Update window size on resize
  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const rotateOrbs = useCallback(() => {
    // Stop rotating orbs if they are hidden or activePage is set
    if (orbsHidden || activePage) return;

    if (isFastRotatingRef.current) {
      rotationPausedRef.current = false; // Ensure rotation continues during fast rotation
      // Do not decelerate during fast rotation
      rotationDegreesRef.current = (rotationDegreesRef.current + rotationSpeedRef.current) % 360;
    } else {
      if (!rotationPausedRef.current) {
        rotationSpeedRef.current = Math.max(rotationSpeedRef.current * decelerationRate, minRotationSpeed);
        rotationDegreesRef.current = (rotationDegreesRef.current + rotationSpeedRef.current) % 360;
      }
    }

    const orbs = orbRefs.current.map((ref) => ref.current).filter(Boolean);

    orbs.forEach((orb, index) => {
      if (!orb) return; // Skip if orb is null

      // Determine if this orb is hovered
      const isHovered = hoveredOrbIndexRef.current === index;
      const targetScale = isHovered ? 1.2 : 1.0;
      const currentScale = parseFloat(orb.dataset.currentScale) || 1.0;

      // Smooth scaling
      const scaleSpeed = 0.1;
      const newScale = currentScale + (targetScale - currentScale) * scaleSpeed;
      orb.dataset.currentScale = newScale;

      // Combine translation and scaling
      const orbBaseAngle = (360 / orbs.length) * index;
      const orbRotationAngle = orbBaseAngle + rotationDegreesRef.current;

      const x =
        center.x + orbitDistance * Math.cos((orbRotationAngle * Math.PI) / 180) - (120 * newScale) / 2;
      const y =
        center.y + orbitDistance * Math.sin((orbRotationAngle * Math.PI) / 180) - (120 * newScale) / 2;

      orb.style.transform = `translate(${x}px, ${y}px) scale(${newScale})`;

      orb.style.opacity = '1';
    });

    // Handle central logo scaling
    const centerLogo = centerLogoRef.current;
    if (centerLogo) {
      const isHovered = isLogoHoveredRef.current;
      const targetScaleLogo = isHovered ? 1.2 : 1.0;
      const currentScaleLogo = parseFloat(centerLogo.dataset.currentScale) || 1.0;
      const newScaleLogo = currentScaleLogo + (targetScaleLogo - currentScaleLogo) * 0.1;
      centerLogo.dataset.currentScale = newScaleLogo;
      centerLogo.style.transform = `translate(${center.x - centerLogo.offsetWidth / 2
        }px, ${center.y - centerLogo.offsetHeight / 2}px) scale(${newScaleLogo})`;
    }

    requestAnimationFrame(rotateOrbs);
  }, [activePage, center.x, center.y, orbitDistance, orbsHidden]);

  // Initialize a ref to track if the initial animation has run
  const initialAnimationRunRef = useRef(false);

  useEffect(() => {
    if (initialAnimationRunRef.current) return; // Prevent re-running the initial animation
    initialAnimationRunRef.current = true;

    const centerLogo = centerLogoRef.current;
    const orbs = orbRefs.current.map((ref) => ref.current).filter(Boolean);

    // Initialize orbs only if they are available
    if (orbs.length > 0) {
      // Initialize orbs
      if (centerLogo) {
        centerLogo.style.transform = `translate(${center.x - centerLogo.offsetWidth / 2}px, ${center.y - centerLogo.offsetHeight / 2
          }px) scale(1.0)`;
        centerLogo.style.opacity = '0';
      }
      orbs.forEach((orb) => {
        if (orb) {
          orb.style.opacity = '0';
        }
      });

      function enableHover() {
        hoverEnabledRef.current = true; // Enable hovering after animation
      }

      function animateSpiralPath(element, delay, isCenterImage, index) {
        const startTime = Date.now() + delay;
        const spiralEndTime = startTime + waveDuration;

        function updatePosition() {
          const now = Date.now();
          if (now >= spiralEndTime) {
            if (isCenterImage) {
              if (element) {
                element.style.opacity = '1';
                element.style.transform = `translate(${center.x - element.offsetWidth / 2}px, ${center.y - element.offsetHeight / 2
                  }px) scale(1.0)`;
              }
            } else {
              if (element) {
                element.style.opacity = '1';
                setTimeout(() => transitionToFastRotation(element, index), index * 100);
              }
            }
            return;
          }

          const t = (now - startTime) / waveDuration;
          const angle = t * 4 * Math.PI; // spiralAngleSpeed = 4 * PI
          const radius = 500 - t * (500 - 50); // spiralRadiusStart = 500, spiralRadiusEnd = 50

          const x = center.x + radius * Math.cos(angle) * 1.5 - element.offsetWidth / 2; // xStretchFactor = 1.5
          const y = center.y + radius * Math.sin(angle) * 0.8 - element.offsetHeight / 2; // yShrinkFactor = 0.8

          if (element) {
            element.style.opacity = (0.5 + t / 2).toString();
            element.style.transform = `translate(${x}px, ${y}px) scale(1.0)`;
          }

          requestAnimationFrame(updatePosition);
        }

        requestAnimationFrame(updatePosition);
      }

      function transitionToFastRotation(orb, index) {
        const initialAngle = (360 / orbs.length) * index;
        const rotationStartTime = Date.now();
        const rotationEndTime = rotationStartTime + fastRotationDuration;

        function updateRotation() {
          const now = Date.now();
          if (now >= rotationEndTime) {
            rotateOrbs();
            return;
          }

          const t = (now - rotationStartTime) / fastRotationDuration;
          const additionalRotation = t * 360;
          const orbRotationAngle = initialAngle + additionalRotation;

          const x =
            center.x + orbitDistance * Math.cos((orbRotationAngle * Math.PI) / 180) - orb.offsetWidth / 2;
          const y =
            center.y + orbitDistance * Math.sin((orbRotationAngle * Math.PI) / 180) - orb.offsetHeight / 2;

          if (orb) {
            orb.style.transform = `translate(${x}px, ${y}px)`;
          }

          requestAnimationFrame(updateRotation);
        }

        requestAnimationFrame(updateRotation);
      }

      function initialAnimation() {
        setTimeout(() => {
          if (centerLogo) {
            animateSpiralPath(centerLogo, 0, true, 0);
          }

          orbs.forEach((orb, index) => {
            if (orb) {
              animateSpiralPath(orb, index * 100, false, index);

              // Initialize scale data attribute
              orb.dataset.currentScale = '1.0';
            }
          });

          // After initial animation, enable hover
          setTimeout(enableHover, waveDuration + 2000);
        }, 200);
      }

      initialAnimation();

      // Start rotating the orbs
      requestAnimationFrame(rotateOrbs);
    }

    // Cleanup function if needed
    return () => {
      // Any necessary cleanup
    };
  }, [orbsData, center, orbitDistance, rotateOrbs]);

  // Handle Hover Events
  const handleMouseEnter = (index) => {
    if (hoverEnabledRef.current && !isFastRotatingRef.current) {
      setHoveredOrbIndex(index);
      rotationPausedRef.current = true;
    }
  };

  const handleMouseLeave = (index) => {
    if (hoverEnabledRef.current && !isFastRotatingRef.current) {
      setHoveredOrbIndex(null);
      rotationPausedRef.current = false;
    }
  };

  // Handle Click Events
  const handleOrbClick = (page, index) => {
    hoverEnabledRef.current = false;
    rotationPausedRef.current = true;
    selectedOrbIndexRef.current = index; // Update ref for immediate access
    animateOrbsOut(page);
  };

  // Handle Logo Click
  const handleLogoClick = () => {
    const orbs = orbRefs.current.map((ref) => ref.current).filter(Boolean);
    if (hoverEnabledRef.current && !isFastRotatingRef.current) {
      isFastRotatingRef.current = true;
      rotationSpeedRef.current = 1; // Fast rotation speed
      rotationPausedRef.current = false; // Ensure rotation continues

      // Disable hovering on orbs during fast rotation
      hoverEnabledRef.current = false;
      setHoveredOrbIndex(null);
      orbs.forEach((orb) => {
        if (orb) {
          // Optionally, reset scale or other properties
        }
      });

      setTimeout(() => {
        // After fast rotation ends
        rotationSpeedRef.current = 5; // Reset to base speed
        isFastRotatingRef.current = false;
        // Allow deceleration to resume if needed
      }, totalFastRotationTime); // Duration of fast rotation

      // Re-enable hover after fast rotation and additional delay
      setTimeout(() => {
        hoverEnabledRef.current = true;
      }, totalFastRotationTime + 500); // Additional 500ms delay
    }
  };

  // Handle Hover Events for Logo
  const handleLogoMouseEnter = () => {
    if (hoverEnabledRef.current) {
      setIsLogoHovered(true);
      // Do not pause rotation
    }
  };

  const handleLogoMouseLeave = () => {
    if (hoverEnabledRef.current) {
      setIsLogoHovered(false);
      // Do not resume rotation since it wasn't paused
    }
  };

  // Handle Back Button
  const handleBackToOrbit = () => {
    // Show orbs again before animation
    setOrbsHidden(false);

    // Fade out the active page
    setActivePage(null);

    // The orbs might not be immediately available after re-rendering,
    // so wait for the next tick to start the animation
    setTimeout(() => {
      animateOrbsIn();
    }, 0);
  };

  // Helper function to get starting position on screen edge based on angle
  // Helper function to get starting position on screen edge based on angle
  function getEdgePosition(angleInDegrees) {
    const angle = (angleInDegrees * Math.PI) / 180;
    const { width, height } = windowSize;

    const centerX = center.x;
    const centerY = center.y;

    const xFactor = Math.cos(angle);
    const yFactor = Math.sin(angle);

    let x, y;

    // Prevent division by zero for vertical lines
    const slope = xFactor !== 0 ? yFactor / xFactor : Infinity;

    // Check intersection with left and right edges
    if (xFactor > 0) {
      // Intersect with right edge
      const xEdge = width;
      const yEdge = centerY + slope * (xEdge - centerX);
      if (yEdge >= 0 && yEdge <= height) {
        x = xEdge;
        y = yEdge;
      }
    } else if (xFactor < 0) {
      // Intersect with left edge
      const xEdge = 0;
      const yEdge = centerY + slope * (xEdge - centerX);
      if (yEdge >= 0 && yEdge <= height) {
        x = xEdge;
        y = yEdge;
      }
    }

    // Check intersection with top and bottom edges if not set
    if (y === undefined) {
      if (yFactor > 0) {
        // Intersect with bottom edge
        const yEdge = height;
        const xEdge = centerX + (yEdge - centerY) / slope;
        if (xEdge >= 0 && xEdge <= width) {
          x = xEdge;
          y = yEdge;
        }
      } else if (yFactor < 0) {
        // Intersect with top edge
        const yEdge = 0;
        const xEdge = centerX + (yEdge - centerY) / slope;
        if (xEdge >= 0 && xEdge <= width) {
          x = xEdge;
          y = yEdge;
        }
      }
    }

    // If still undefined, default to center (prevents NaN)
    if (x === undefined || y === undefined) {
      x = centerX;
      y = centerY;
    }

    // Adjust for orb dimensions (assuming orb size is 120x120)
    const orbWidth = 120;
    const orbHeight = 120;

    let adjustedX = x - orbWidth / 2;
    let adjustedY = y - orbHeight / 2;

    // Clamp the positions to ensure orbs stay within the viewport
    adjustedX = Math.max(0, Math.min(adjustedX, width - orbWidth));
    adjustedY = Math.max(0, Math.min(adjustedY, height - orbHeight));

    return { x: adjustedX, y: adjustedY };
  }

  // Animate Orbs Back In from Edge Positions
  function animateOrbsIn() {
    const orbs = orbRefs.current.map((ref) => ref.current).filter(Boolean);
    const centerLogo = centerLogoRef.current;

    if (orbs.length === 0 || !centerLogo) {
      // Orbs or center logo not yet available, wait a bit and try again
      setTimeout(animateOrbsIn, 50);
      return;
    }

    const duration = 1000;
    const startTime = Date.now();

    // Reset rotation degrees
    rotationDegreesRef.current = 0;

    // Assign orbs to starting positions based on their orbital angles
    orbs.forEach((orb, index) => {
      if (!orb) return;

      // Calculate the angle for this orb
      const orbAngle = (360 / orbs.length) * index;

      // Convert angle to radians
      const angleRad = (orbAngle * Math.PI) / 180;

      // Compute the ending positions (orbital positions)
      const orbEndX =
        center.x + orbitDistance * Math.cos(angleRad) - orb.offsetWidth / 2;
      const orbEndY =
        center.y + orbitDistance * Math.sin(angleRad) - orb.offsetHeight / 2;

      // Get starting position on screen edge
      const { x: startX, y: startY } = getEdgePosition(orbAngle);

      // Set initial position and opacity
      orb.style.transform = `translate(${startX}px, ${startY}px) scale(1.0)`;
      orb.style.opacity = '0';

      // Store positions for animation
      orb._startX = startX;
      orb._startY = startY;
      orb._endX = orbEndX;
      orb._endY = orbEndY;

      // Initialize scale data attribute
      orb.dataset.currentScale = '1.0';
    });

    // Initialize center logo
    if (centerLogo) {
      centerLogo.style.opacity = '0';
      centerLogo.style.transform = `translate(${center.x - centerLogo.offsetWidth / 2}px, ${center.y - centerLogo.offsetHeight / 2
        }px) scale(1.0)`;
    }

    // Disable interactions during animation
    hoverEnabledRef.current = false;
    setHoveredOrbIndex(null);
    setIsLogoHovered(false);
    rotationPausedRef.current = true;

    // Start the animation
    animate();

    function animate() {
      const now = Date.now();
      const t = Math.min((now - startTime) / duration, 1); // Ensure t doesn't exceed 1

      // Animate orbs to their orbital positions
      orbs.forEach((orb) => {
        if (!orb) return;

        const newX = orb._startX + (orb._endX - orb._startX) * t;
        const newY = orb._startY + (orb._endY - orb._startY) * t;

        orb.style.transform = `translate(${newX}px, ${newY}px) scale(1.0)`;
        orb.style.opacity = `${t}`;
      });

      // Fade in the center logo
      if (centerLogo) {
        centerLogo.style.opacity = `${t}`;
      }

      if (t < 1) {
        requestAnimationFrame(animate);
      } else {
        // Finalize positions
        orbs.forEach((orb) => {
          if (!orb) return;

          orb.style.opacity = '1';
          orb.style.transform = `translate(${orb._endX}px, ${orb._endY}px) scale(1.0)`;
        });

        if (centerLogo) {
          centerLogo.style.opacity = '1';
          centerLogo.style.transform = `translate(${center.x - centerLogo.offsetWidth / 2}px, ${center.y - centerLogo.offsetHeight / 2
            }px) scale(1.0)`;
        }

        // Re-enable interactions
        rotationSpeedRef.current = minRotationSpeed; // Start them off slowly
        hoverEnabledRef.current = true;
        rotationPausedRef.current = false;

        // Resume orbit rotation
        requestAnimationFrame(rotateOrbs);

      }
    }
  }

  // Animate Orbs Outwards When an Orb is Clicked
  function animateOrbsOut(page) {
    const duration = 1000;
    const startTime = Date.now();
    const centerLogo = centerLogoRef.current;
    const orbs = orbRefs.current.map((ref) => ref.current);

    function animate() {
      const now = Date.now();
      const t = (now - startTime) / duration;

      if (t >= 1) {
        // Finalize states
        orbs.forEach((orb, i) => {
          if (orb) {
            orb.style.opacity = '0';
          }
        });
        if (centerLogo) {
          centerLogo.style.opacity = '0';
        }
        setOrbsHidden(true);
        setActivePage(page);
        selectedOrbIndexRef.current = null; // Reset the ref

        return;
      }

      orbs.forEach((orb, i) => {
        if (!orb) return;

        // Use the ref to get the current selected orb index
        if (i === selectedOrbIndexRef.current) {

          // Fade out the selected orb in place
          const currentOpacity = 1 - t;
          orb.style.opacity = `${currentOpacity}`;
          // No transform change to keep it in place
        } else {

          // Animate other orbs outward and fade them
          const orbRect = orb.getBoundingClientRect();
          const orbX = orbRect.left + orbRect.width / 2;
          const orbY = orbRect.top + orbRect.height / 2;

          const dirX = orbX - center.x;
          const dirY = orbY - center.y;

          const newX = orbX + dirX * t * 2;
          const newY = orbY + dirY * t * 2;

          orb.style.transform = `translate(${newX - orbRect.width / 2}px, ${newY - orbRect.height / 2}px) scale(1.0)`;
          orb.style.opacity = `${1 - t}`;
        }
      });

      if (centerLogo) {
        centerLogo.style.opacity = `${1 - t}`;
      }

      requestAnimationFrame(animate);
    }

    animate();
  }
  // New function to handle info click, similar to handleOrbClick but no selected orb
  const handleInfoClick = () => {
    hoverEnabledRef.current = false;
    rotationPausedRef.current = true;
    selectedOrbIndexRef.current = null; // No particular orb selected
    animateOrbsOut('Info'); // Animate out everything and show Info page after
  };



  return (
    <>
      <WelcomeMessage />
      <TopRightMenu onInfoClick={handleInfoClick} /> {/* Add the TopRightMenu here */}
      <div className={`orbital-layout ${orbsHidden ? 'orbs-hidden' : ''}`} ref={orbitalLayoutRef}>
        {!activePage && (
          <>
            <div
              className="center-logo"
              ref={centerLogoRef}
              onMouseEnter={handleLogoMouseEnter}
              onMouseLeave={handleLogoMouseLeave}
              onClick={handleLogoClick}
            >
              <img src={logoImage} alt="Logo" />
            </div>

            {orbsData.map((label, index) => {
              if (!orbRefs.current[index]) {
                orbRefs.current[index] = React.createRef();
              }
              return (
                <Orb
                  key={label}
                  label={label}
                  ref={orbRefs.current[index]}
                  onMouseEnter={() => handleMouseEnter(index)}
                  onMouseLeave={() => handleMouseLeave(index)}
                  onClick={() => handleOrbClick(orbsData[index], index)}
                />
              );
            })}

            <div className="email-text">info@korbnet.com</div>
          </>
        )}
      </div>
      {activePage && (
        <div className="page-content">
          {activePage === 'About' && <About onBack={handleBackToOrbit} />}
          {activePage === 'Skills' && <Skills onBack={handleBackToOrbit} />}
          {activePage === 'Career' && <Career onBack={handleBackToOrbit} />}
          {activePage === 'Contact' && <Contact onBack={handleBackToOrbit} />}
          {activePage === 'Projects' && <Projects onBack={handleBackToOrbit} />}
          {activePage === 'Info' && <Info onBack={handleBackToOrbit} />} {/* Show Info page */}
        </div>
      )}
    </>
  );
};

export default OrbitalLayout;
