import React, { useState } from 'react';
import './App.css';
import OrbitalLayout from './components/OrbitalLayout';
import BubbleContainer from './components/BubbleContainer';
import TopRightMenu from './components/TopRightMenu';
import Info from './pages/Info';

function App() {
  const [activePage, setActivePage] = useState(null);

  const handleInfoClick = () => {
    setActivePage('Info');
  };

  const handleBackToOrbit = () => {
    setActivePage(null);
  };

  return (
<div className="App">
      <OrbitalLayout />
      <BubbleContainer />
    </div>
  );
}

export default App;
