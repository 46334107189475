// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/styles/Bubble.css */
.bubble-rise {
    position: fixed;
    bottom: -100px;
    border-radius: 50%;
    background-color: #f0f0f0;
    opacity: 0.8;
    z-index: 1500;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
    animation: rise linear forwards;
    cursor: pointer;
  }
  
  @keyframes rise {
    from {
      transform: translateY(0);
    }
    to {
      transform: translateY(-150vh); 
    }
  }
  
  
  .emoji {
    position: absolute;
    transform: translate(-50%, -50%);
    font-size: 2rem;
    z-index: 2000;
    animation: fadeOutEmoji 1s forwards;
  }
  
  @keyframes fadeOutEmoji {
    0% {
      opacity: 1;
      transform: translate(-50%, -50%) scale(1);
    }
    100% {
      opacity: 0;
      transform: translate(-50%, -50%) scale(1.5);
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/styles/Bubble.css"],"names":[],"mappings":"AAAA,0BAA0B;AAC1B;IACI,eAAe;IACf,cAAc;IACd,kBAAkB;IAClB,yBAAyB;IACzB,YAAY;IACZ,aAAa;IACb,2CAA2C;IAC3C,+BAA+B;IAC/B,eAAe;EACjB;;EAEA;IACE;MACE,wBAAwB;IAC1B;IACA;MACE,6BAA6B;IAC/B;EACF;;;EAGA;IACE,kBAAkB;IAClB,gCAAgC;IAChC,eAAe;IACf,aAAa;IACb,mCAAmC;EACrC;;EAEA;IACE;MACE,UAAU;MACV,yCAAyC;IAC3C;IACA;MACE,UAAU;MACV,2CAA2C;IAC7C;EACF","sourcesContent":["/* src/styles/Bubble.css */\n.bubble-rise {\n    position: fixed;\n    bottom: -100px;\n    border-radius: 50%;\n    background-color: #f0f0f0;\n    opacity: 0.8;\n    z-index: 1500;\n    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);\n    animation: rise linear forwards;\n    cursor: pointer;\n  }\n  \n  @keyframes rise {\n    from {\n      transform: translateY(0);\n    }\n    to {\n      transform: translateY(-150vh); \n    }\n  }\n  \n  \n  .emoji {\n    position: absolute;\n    transform: translate(-50%, -50%);\n    font-size: 2rem;\n    z-index: 2000;\n    animation: fadeOutEmoji 1s forwards;\n  }\n  \n  @keyframes fadeOutEmoji {\n    0% {\n      opacity: 1;\n      transform: translate(-50%, -50%) scale(1);\n    }\n    100% {\n      opacity: 0;\n      transform: translate(-50%, -50%) scale(1.5);\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
