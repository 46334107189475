// src/components/BubbleContainer.js
import React, { useEffect, useState, useRef, useCallback } from 'react';
import Bubble from './Bubble';
import ScoreCounter from './ScoreCounter';
import '../styles/BubbleContainer.css';

const messages = [
  '👍', '🎉', '😊', '👏', '🚀', '💯', '🪶',
  '🌟', '💫', '🔥', '🌈', '🎈', '🍀', '🦄',
  '😎', '🧠', '💼', '🎮', '🏆', '🕹️', '🎸',
  '🚴', '🧩', '🎲', '🔮', '🕶️', '🌍', '🥇',
  '🤖', '🚀', '🧲', '🪐', '✨', '🌙', '🎭'
];

const MAX_BUBBLES = 20;

const BubbleContainer = () => {
  const [bubbles, setBubbles] = useState([]);
  const [emojis, setEmojis] = useState([]);
  const [score, setScore] = useState(0);
  const intervalRef = useRef(null);

  // Memoize createBubble to prevent unnecessary re-creations
  const createBubble = useCallback(() => {
    setBubbles((prev) => {
      if (prev.length >= MAX_BUBBLES) return prev;
      const id = Date.now() + Math.random();
      return [...prev, { id }];
    });
  }, []);

  const handlePop = useCallback((id, position) => {
    setBubbles((prev) => prev.filter((bubble) => bubble.id !== id));

    // Only increment the score if the bubble was clicked
    if (position) {
      setScore((prev) => prev + 1);

      // Generate and display an emoji at the click position
      const emoji = messages[Math.floor(Math.random() * messages.length)];
      setEmojis((prev) => [...prev, { id, emoji, position }]);

      // Remove the emoji after the animation completes
      setTimeout(() => {
        setEmojis((prev) => prev.filter((e) => e.id !== id));
      }, 1000); // Matches fade-out animation duration
    }
  }, []);

  useEffect(() => {
    const startBubbleInterval = () => {
      if (!intervalRef.current) {
        intervalRef.current = setInterval(createBubble, 2000);
      }
    };

    const stopBubbleInterval = () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    };

    const handleVisibilityChange = () => {
      if (document.hidden) {
        stopBubbleInterval();
      } else {
        startBubbleInterval();
      }
    };

    // Start the interval when the component mounts
    startBubbleInterval();
    // Add the visibility change listener
    document.addEventListener('visibilitychange', handleVisibilityChange);

    // Cleanup on unmount
    return () => {
      stopBubbleInterval();
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [createBubble]); // Note: createBubble is stable due to useCallback

  return (
    <div className="bubble-container">
      <ScoreCounter count={score} />
      {bubbles.map((bubble) => (
        <Bubble key={bubble.id} id={bubble.id} onPop={handlePop} />
      ))}
      {emojis.map(({ id, emoji, position }) => {
        if (!position) return null; // Skip if no position
        return (
          <span
            key={id}
            className="emoji"
            style={{
              top: `${position.y}px`,
              left: `${position.x}px`,
            }}
          >
            {emoji}
          </span>
        );
      })}
    </div>
  );
};

export default BubbleContainer;
