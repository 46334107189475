// src/components/ScoreCounter.js
import React from 'react';
import '../styles/ScoreCounter.css';

const ScoreCounter = ({ count }) => {
  return (
    <div id="score-counter" aria-label="Score Counter">
      {count}
    </div>
  );
};

export default ScoreCounter;
