import React from 'react';
import '../styles/PageStyles.css';

const Info = ({ onBack }) => {
  return (
    <div className="page-container">
      <h1>Information</h1>
      <p>Welcome to the Information Page! Here you can find more details about korbnet, our mission, and the services we offer.</p>
      <button className="back-button" onClick={onBack}>Back</button>
    </div>
  );
};

export default Info;
