import React, { useState } from 'react';
import '../../styles/PageStyles.css';
import { projectsData } from './data';
import ProjectDetail from './ProjectDetail';

const Projects = ({ onBack }) => {
  const [selectedProject, setSelectedProject] = useState(null);

  // Extract and sort projects by date
  const sortedProjects = [...projectsData].sort((a, b) => {
    const dateAString = a.details.match(/<div class="project-date">(.*?)<\/div>/)[1];
    const dateBString = b.details.match(/<div class="project-date">(.*?)<\/div>/)[1];
    
    const dateA = new Date(`1 ${dateAString}`);
    const dateB = new Date(`1 ${dateBString}`);
    
    return dateB - dateA; // Newest to oldest
  });
  
  const handleProjectClick = (project) => {
    setSelectedProject(project);
  };

  const handleBackToList = () => {
    setSelectedProject(null);
  };

  if (selectedProject) {
    return <ProjectDetail project={selectedProject} onBack={handleBackToList} />;
  }

  return (
    <div className="page-container">
      <button className="back-button" onClick={onBack}>
        Back
      </button>
      <h1 className="info-title">Projects Made</h1>
      <p>Explore some of the projects:</p>
      <div className="project-grid">
        {sortedProjects.map((project) => {
          const dateMatch = project.details.match(/<div class="project-date">(.*?)<\/div>/);
          const projectYear = dateMatch ? dateMatch[1] : 'Unknown';

          return (
            <div key={project.id} className="project-tile" onClick={() => handleProjectClick(project)}>
              <span className="year-badge">{projectYear}</span> {/* Badge */}
              <img src={project.image} alt={project.alt} className="project-preview-image" loading="lazy" />
              <div className="project-title">{project.title}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Projects;
